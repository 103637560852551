import { ImageToCSS } from "../../reactor/Types/File"
import { UntitledUI } from "../untitled-ui"
import type { Chip } from "./Chip"
import { RegisterWidget } from "./WidgetView"
import { ColorStyle, ColorStyles } from "../ui"
import { Icon } from "../../studio/Views/Icon"
import { Color } from "../../reactor"

RegisterWidget<Chip>("Chip", ChipWidget)

export function ChipWidget({ value }: { value: Chip }) {
    const colorSeed = value.text || value.icon

    const colors = value.color
        ? value.color in ColorStyles
            ? ((ColorStyles as any)[value.color] as ColorStyle)
            : value.color
        : colorSeed
          ? UntitledUI.randomSecondaryColor(colorSeed)
          : ColorStyles.gray

    const dark = typeof colors === "object" ? undefined : Color.isDark(colors)

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div
                style={{
                    backgroundColor:
                        typeof colors === "object" ? colors[value.backgroundWeight ?? 50] : colors,
                    color:
                        dark === true
                            ? "white"
                            : dark === false
                              ? "black"
                              : value.foregroundWeight === 0
                                ? "white"
                                : colors[value.foregroundWeight ?? 700],
                    borderRadius: 16,

                    paddingRight: 8,
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 2,
                    maxHeight: 30,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                {value.image && (
                    <div
                        style={{
                            display: "flex",
                            width: 24,
                            height: 24,
                            backgroundImage: ImageToCSS(value.image, { width: 24 }),
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderRadius: 12,
                        }}
                    />
                )}
                <div
                    style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 8,
                        display: "flex",
                        flexDirection: "row",

                        // if the strikeThrough prop is true, add a line-through style
                        textDecoration: value.strikeThrough ? "line-through" : "none",
                    }}
                >
                    {value.icon && (
                        <Icon
                            icon={value.icon}
                            style={{
                                color: colors[500],
                                marginRight: value.text
                                    ? value.icon.startsWith("ui-")
                                        ? 6
                                        : 4
                                    : 0,
                                marginTop: !value.icon || value.icon.startsWith("ui-") ? 0 : 3,
                                marginLeft: !value.icon || value.icon.startsWith("ui-") ? 0 : 3,
                                width: 16,
                            }}
                        />
                    )}
                    {value.text}
                </div>
            </div>
        </div>
    )
}
