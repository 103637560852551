import { DateString } from "./Types/Primitives/DateTime"
import { Uuid } from "./Types/Primitives/Uuid"
import { Type, IsStringType, IsNumberType, IsBooleanType, IsDateType } from "./Types/Type"

export type OrderByType = string | number | Date | boolean | DateString | Uuid<any>
export function IsOrderByType(type: Type) {
    return IsStringType(type) || IsNumberType(type) || IsDateType(type) || IsBooleanType(type)
}

export type OrderBySelector<T> = (item: T) => OrderByType

export type OrderByMode = "ascending" | "descending"

export type OrderByOrdering = [OrderBySelector<any>, OrderByMode][]

/** Returns a new array containing the items from the input array ordered by the given selector and
 * order mode. The input array is not changed. */
export function OrderBy<T>(
    input: readonly T[],
    selector: OrderBySelector<T>,
    mode: OrderByMode = "ascending"
) {
    return OrderByMultiple(input, [[selector, mode]])
}

/**
 * Returns a new array containing the items from the input array ordered by the given selectors and
 * order modes. The input array is not changed.
 */
/**
 * Returns a new array containing the items from the input array ordered by the given selectors and
 * order modes. The input array is not changed.
 */
export function OrderByMultiple<T>(input: readonly T[], orderings: OrderByOrdering) {
    const res = input.slice().sort((a, b) => {
        for (let i = 0; i < orderings.length; i++) {
            const [selector, mode] = orderings[i]
            let xa: any = selector(a)
            let xb: any = selector(b)

            // Be a little pragmatic
            if (xa === xb) continue
            if (xa === undefined && typeof xb === "string") xa = ""
            if (xb === undefined && typeof xa === "string") xb = ""
            if (xa === undefined && typeof xb === "number") xa = 0
            if (xb === undefined && typeof xa === "number") xb = 0
            if (xa === undefined && typeof xb === "boolean") xa = false
            if (xb === undefined && typeof xa === "boolean") xb = false
            if (xa === null && typeof xb === "string") xa = ""
            if (xb === null && typeof xa === "string") xb = ""
            if (xa === null && typeof xb === "number") xa = 0
            if (xb === null && typeof xa === "number") xb = 0
            if (xa === null && typeof xb === "boolean") xa = false
            if (xb === null && typeof xa === "boolean") xb = false

            let comparison = 0
            if (typeof xa === "string" && typeof xb === "string") {
                comparison = xa.localeCompare(xb)
            } else if (typeof xa === "number" && typeof xb === "number") {
                comparison = xa - xb
            } else if (xa instanceof Date && xb instanceof Date) {
                comparison = xa.getTime() - xb.getTime()
            } else if (typeof xa === "boolean" && typeof xb === "boolean") {
                comparison = (xa ? 1 : 0) - (xb ? 1 : 0)
            } else if (typeof xa === "undefined" && typeof xb === "undefined") {
                continue
            } else {
                throw new Error(
                    "Unsupported sort: " + JSON.stringify(xa) + " :: " + JSON.stringify(xb)
                )
            }

            if (mode === "descending") {
                comparison *= -1
            }

            if (comparison !== 0) {
                return comparison
            }
        }
        return 0
    })
    return res
}
